export const isValidISODate = (raw: string): boolean => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(raw)) return false;
    const d = new Date(raw);
    return d instanceof Date && d.toISOString() === raw; // valid date
};

// TODO: do this in fs-date
export const dateFormat = (date: string) => {
    const formattedDate = new Date(date);
    const month = formattedDate.getMonth() + 1;
    const day = formattedDate.getDate();
    const year = formattedDate.getFullYear();

    return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
};
